/**
 * Offcanvas
 *
 * @summary Offcanvas ispirato da https://github.com/danielguillan/flyout
 * @global jQuery
 * @author Matteo Casonato
 */
 
(function($, undefined) {
    'use strict';

    function Offcanvas() {

        var offcanvas = {
            nav: null,
            currentSide: null,
            isOpen: false,
            isExpanded: false,

            open: function(side) {
                this.body.addClass('active-offcanvas active-offcanvas--'+side);
                offcanvas.nav.filter('.offcanvas--'+side).addClass('is-active');
                offcanvas.isOpen = true;
                offcanvas.currentSide = side;
            },

            close: function() {
                if (offcanvas.isExpanded) {
                    offcanvas.collapse();
                }
                this.body.removeClass('active-offcanvas active-offcanvas--left active-offcanvas--right');
                offcanvas.nav.removeClass('is-active');
                offcanvas.isOpen = false;
                offcanvas.currentSide = undefined;
            },

            expand: function() {
                this.body.addClass('active-offcanvas--expand');
                offcanvas.isExpanded = true;
            },

            collapse: function() {
                this.body.removeClass('active-offcanvas--expand');
                offcanvas.isExpanded = false;
            },

            init: function() {
                this.body = $('body, html');

                offcanvas.nav = $('.offcanvas');

                $(document).on('click', '.js-offcanvas-link', function(e){

                    var $this = $(this),
                    side = $this.data('offcanvas-side'),
                    expand = $this.data('offcanvas-expand');

                    if (expand) {
                        offcanvas.isExpanded === true ? offcanvas.collapse() : offcanvas.expand();
                    } else {
                        offcanvas.isOpen ? offcanvas.close() : offcanvas.open(side);
                    }
                    e.preventDefault(e);
                });

                return this;
            }
        };

        offcanvas.init();

        return {
            open: offcanvas.open,
            close: offcanvas.close,
            expand: offcanvas.expand,
            collapse: offcanvas.collapse
        };
    }

    window.Offcanvas = new Offcanvas();

})(window.jQuery);