/**
 * Navigation
 *
 * @summary Navigazione Mobile e Desktop
 * @global jQuery, modernizr
 * @requires libraries/debounce.js
 * @author Daniele De Nobili
 */

(function ($) {
	'use strict';

    var
        // Elementi DOM
        menu = $('#js-menu'),
        menuNav = menu.children('.menu__nav'),
        menuToggleButton = $('.js-offcanvas-link'),
        menuIn,

        // Variabili per controllare l’animazione
        Modernizr = window.Modernizr,
        supportAnimations = Modernizr.cssanimations,
        animationEndEventNames = {
            'WebkitAnimation': 'webkitAnimationEnd',
            'OAnimation': 'oAnimationEnd',
            'msAnimation': 'MSAnimationEnd',
            'animation': 'animationend'
        },
        animationEventName = animationEndEventNames[Modernizr.prefixed('animation')],

        // Funzioni
        onArrowClick,
        onBackClick;

    menuNav.find('ul').addClass('menu__subnav');

    // Preparo il menu aggiungendo gli elementi di navigazione, come le frecce e le voci per tornare indietro.
    function prepareMenu() {
        menuNav
            .find('li.dropdown')
            .each(function () {
                var li = $(this);

                li.children('ul')
                    .prepend('<li class="menu__subnav__back"><span class="menu__arrow menu__arrow--back"></span>' + li.children('.menu__url').eq(0).text() + '</li>');
            })
            .prepend('<span class="menu__arrow menu__arrow--next" />');
    }

    function onClickToggleButton(event) {
        event.preventDefault();
        menu.toggleClass('menu--open');
    }

    onArrowClick = function (event) {
        var $el = $(this),
            $item = $el.closest('li'),
            $subMenu = $item.children('.menu__subnav');

        function onAnimationEnd() {
            menuNav
                .off(animationEventName)
                .removeClass('menu-animate-out')
                .addClass('menu__subview');

            $item
                .addClass('menu__subview--open')
                .parents('.menu__subview--open:first')
                .removeClass('menu__subview--open')
                .addClass('menu__subview');

            menuIn.remove();
        }

        if ($subMenu.length > 0) {
            event.preventDefault();
            event.stopPropagation();

            menuIn = $subMenu
                .clone()
                .css('opacity', 0)
                .insertAfter(menuNav);

            setTimeout(function () {
                menuNav.addClass('menu-animate-out');
                menuIn.addClass('menu-animate-in');

                if (supportAnimations) {
                    menuNav.on(animationEventName, onAnimationEnd);
                } else {
                    onAnimationEnd();
                }
            });
        }
    };

    onBackClick = function (event) {
        event.preventDefault();
        event.stopPropagation();

        var $this = $(this),
            $submenu = $this.parents('.menu__subnav:first'),
            $item = $submenu.parent(),
            $flyin = $submenu.clone().insertAfter(menuNav),
            $subview;

        function onAnimationEnd() {
            menuNav.off(animationEventName).removeClass('menu-animate-in');
            $flyin.remove();
        }

        setTimeout(function () {
            $flyin.addClass('menu-animate-out');
            menuNav.addClass('menu-animate-in');

            if (supportAnimations) {
                menuNav.on(animationEventName, onAnimationEnd);
            } else {
                onAnimationEnd();
            }

            $item.removeClass('menu__subview--open');

            $subview = $this.parents('.menu__subview:first');

            if ($subview.is('li')) {
                $subview.addClass('menu__subview--open');
            }

            $subview.removeClass('menu__subview');
        });
    };

    function onMobile() {
        //console.log('onMobile');

        // Classe mobile / desktop
        menu.removeClass('menu--desktop').addClass('menu--mobile');

        // Pulizie
        $.map(['menu__subview--open', 'menu__subview', 'menu-animate-in', 'menu-animate-out'], function (className) {
            menu.find('.' + className).removeClass(className);
        });

        // Toggle sul pulsante "Menu"
        menuToggleButton.on('click', onClickToggleButton);

        // Animazione in avanti
        menuNav.on('click', '.menu__arrow--next', onArrowClick);
        // Animazione indietro
        menuNav.on('click', '.menu__subnav__back', onBackClick);
    }

    function onDesktop() {
        //console.log('onDesktop');

        // Classe mobile / desktop
        menu.removeClass('menu--mobile menu--open').addClass('menu--desktop');

        // Pulizie
        $.map(['menu__subview--open', 'menu__subview', 'menu-animate-in', 'menu-animate-out'], function (className) {
            menu.find('.' + className).removeClass(className);
        });

        // Rimuovo l’evento click per il toggle sul pulsante "Menu"
        menuToggleButton.off('click', onClickToggleButton);

        // Rimuovo le animazioni
        menuNav.off('click', '.menu__arrow--next', onArrowClick);
        menuNav.off('click', '.menu__subnav__back', onBackClick);
    }

    prepareMenu();

    var mode;

    function mediaquery() {
        if (mode !== 'desktop' && Modernizr.mq('(min-width: 992px)')) {
            //console.log('> 1024');
            mode = 'desktop';
            onDesktop();
        } else if (mode !== 'mobile') {
            //console.log('< 1024');
            mode = 'mobile';
            onMobile();
        }
    }

    //// Call on every window resize
    $(window).smartresize(mediaquery);

    // Call once on initial load
    mediaquery();


}(jQuery));