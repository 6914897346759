/**
 * Pages
 *
 * @global jQuery
 * @requires plugins/jquery.matchHeight.js
 * @requires plugins/jquery.slick.js
 * @author Matteo Casonato
 */


/* Home Page: Spotlight */
(function ($) {
	'use strict';

	$('#js-spotlight').slick({
        mobileFirst     : true,
		vertical		: false,
		arrows			: false,
		autoplay		: true,
        autoplaySpeed   : 4000,
		dots			: true,
		infinite		: false,
		speed			: 300,
		slidesToShow	: 1,
        adaptiveHeight  : true
	});

}(jQuery));



/* Gallery */
(function ($) {
	'use strict';
			
    $('#js-gallery').lightGallery({
        selector: '.gallery__item a',
        download: false,
    });

}(jQuery));